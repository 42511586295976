<template>
    <div>
        <div class="banner homeBanner" id="mainSwiper" v-if="bannerList.length>0">
            <div class="swiper">
                <div class="swiper-container" ref="homeSwiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper-no-swiping" v-for="(carousel,index) in bannerList" :key="index">
                            <a :href="carousel.uri" target="_blank">
                                <img :src="carousel.logo" alt="" />
                            </a>
                        </div>
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>

                    <!-- 如果需要导航按钮 -->
                    <!-- <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div> -->
                </div>
            </div>
            <div class="search" v-if="search_type.length>0">
                <div class="search-select" @click="searchType">
                    <div :style="{color:search_select==index?'#fff':'rgba(255,255,255,0.7)'}"
                        v-for="(item,index) in search_type" :key="item.id" :data-index="index"
                        :data-classcode="item.classcode">{{item.name}}</div>
                </div>
                <div class="search-arrow">
                    <div :style="{transform:'translateX('+search_arr+'px)'}"></div>
                </div>
                <div class="search-input">
                    <input type="text" v-model="search_text" :placeholder="'请输入关键词，搜索'+search_type[search_select].name"
                        @keyup.enter="Search">
                    <div class="search-btn" @click="Search">
                        <div class="search-icon"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w1703 main">
            <el-skeleton style="width: 100%" :loading="loading" animated :throttle="500">
                <template slot="template">
                    <div>
                        <div class="con-title">
                            <div class="c-tit">
                                <el-skeleton-item variant="h1" style="width:200px;" />
                            </div>
                            <div class="c-txt">
                                <el-skeleton-item variant="text" style="width:300px;" />
                            </div>
                        </div>
                        <div class="compilation-wrap d-model">
                            <div class="con-l">
                                <div class="title_bt">
                                    <div class="bt">
                                        <el-skeleton-item variant="h1" style="width:50%;" />
                                    </div>
                                    <!-- <div class="more">查看更多 <i class="icon"></i></div> -->
                                </div>
                                <div class="model-item" v-for="index in 2" :key="index">
                                    <a href="javascript:;" class="model-cover">
                                        <el-skeleton-item variant="image" style="width: 100%; height: 100%;" />
                                    </a>
                                </div>
                            </div>
                            <div class="con-r">
                                <div class="title_bt">
                                    <div class="bt">
                                        <el-skeleton-item variant="h1" style="width:50%;" />
                                    </div>
                                    <div class="more">
                                        <el-skeleton-item variant="text" style="40%" />
                                    </div>
                                </div>
                                <ul class="r-list">
                                    <li class="model-item" v-for="index2 in 6" :key="index2">
                                        <a href="javascript:;" class="model-cover">
                                            <el-skeleton-item variant="image" style="width: 100%; height: 100%;" />
                                            <div class="model-info">
                                                <p>
                                                    <el-skeleton-item variant="text" style="80%" />
                                                </p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
                <template>
                    <div v-for="(item,index) in info" :key="index">
                        <div class="con-title">
                            <div class="c-tit">{{item.title}}</div>
                            <div class="c-txt">{{item.desc}}</div>
                        </div>
                        <div class="compilation-wrap d-model">
                            <div class="con-l">
                                <div class="title_bt">
                                    <div class="bt">精选{{item.title}}</div>
                                    <!-- <div class="more">查看更多 <i class="icon"></i></div> -->
                                </div>
                                <div class="model-item" v-for="(item2,index2) in item.data.banners" :key="index2">
                                    <a target="_blank" :href="item2.url" class="model-cover">
                                        <el-image :src="item2.logo" lazy></el-image>
                                        <!-- <img :src="item2.logo" alt=""> -->
                                    </a>
                                </div>
                            </div>
                            <div class="con-r">
                                <div class="title_bt">
                                    <div class="bt">热门{{item.title}}</div>
                                    <div class="more"
                                        @click="Link('list', {jump_type:item.jump_type,classcode:item.classcode}, false, true)">
                                        查看更多
                                        <i class="icon"></i></div>
                                </div>
                                <ul class="r-list">
                                    <li class="model-item" v-for="(item2,index2) in item.data.products" :key="index2">
                                        <router-link target="_blank" :to="{name:'listDetail',query:{id:item2.id}}" class="model-cover">
                                            <el-image :src="item2.logo" :alt="item2.title" lazy></el-image>
                                            <!-- <img :src="item2.logo" :alt="item2.title"> -->
                                            <div class="model-info">
                                                <p>{{item2.title}}</p>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
        <div class="w1703" v-if="lessonList.classinfo">
            <div class="con-title">
                <div class="c-tit">{{lessonList.classinfo.classname}}</div>
                <div class="c-txt">{{lessonList.classinfo.desc}}</div>
                <div class="more"
                    @click="Link('list',{jump_type:lessonList.classinfo.jump_type,classcode:lessonList.classinfo.classcode}, false, true)">
                    查看更多 <i class="icon"></i>
                </div>
            </div>
            <div class="edu-list ">
                <div class="edu-item" v-for="(item) in lessonList.list" :key="item.id">
                    <div class="imgInfo">
                        <router-link target="_blank" :to="{name:'lessonInfo',query:{id:item.id}}" class="list-img">
                            <img :src="item.logo" alt="">
                        </router-link>
                        <div class="list-info">
                            <router-link target="_blank" :to="{name:'lessonInfo',query:{id:item.id}}" class="list-info-title">
                                {{item.title}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import {
        getMainCategoryList,
        getIndexCateInfo,
        getIndexBanner,
        getHomeVideoList
    } from '@/api/index.js'
    export default {
        data() {
            return {
                bannerList: [],
                search_arr: 0,
                search_type: [],
                search_select: 0,
                search_code: '',
                info: [],
                loading: true,
                lessonList: {},
                search_text: '',

            }
        },
        methods: {
            searchType(event) {
                let el = event.target;
                let {
                    index,
                    classcode
                } = el.dataset;
                if (index) {
                    this.search_select = index;
                    this.search_code = classcode;
                    this.search_arr = el.offsetLeft + (el.offsetWidth + 24) / 2 - 44;
                }
            },
            Search() {
                this.Link('list', {
                    classcode: this.search_code,
                    keyword: this.search_text
                })
            },
            getBanner() {
                getIndexBanner().then(r => {
                    this.bannerList = r;
                })
            },
            getLessonList() {
                getHomeVideoList().then(r => {
                    this.lessonList = r;
                })
            }
        },
        mounted() {
            //
            this.getBanner();

            let t = this.$util.getStorage('menu');
            if (!t || t == '') {
                getMainCategoryList().then(r => {
                    this.search_type = r;
                    this.search_code = r[0].classcode;
                })
            } else {
                this.search_type = t;
                this.search_code = t[0].classcode;
            }
            getIndexCateInfo().then(r => {
                this.info = r;
                this.$forceUpdate();
                this.$nextTick(() => {
                    // setTimeout(() => {
                    this.loading = false;
                    // }, 1000);
                })
            })
            this.getLessonList();

        },
        components: {},
        watch: {
            bannerList: {
                immediate: true,
                handler() {
                    this.$nextTick(() => {
                        var mySwiper = new Swiper(this.$refs.homeSwiper, {
                            loop: true,
                            autoplay: {
                                disableOnInteraction: false,
                            },
                            noSwiping: true,
                            //如果需要分页器
                            pagination: {
                                el: ".swiper-pagination",
                                clickable: true,
                            },
                            //如果需要前进后退按钮
                            // navigation: {
                            //   nextEl: ".swiper-button-next",
                            //   prevEl: ".swiper-button-prev",
                            // },
                        });
                    });
                },
            },
        },
    }
</script>

<style lang="less" scoped>
    .banner {
        position: relative;
        cursor: pointer;
        min-height: 800px;

        .swiper {
            width: 100%;
            height: auto;

            img {
                width: 100%;
                height: 100%;
            }

            .slick-dots {
                position: absolute;
                bottom: 16px;
                display: block;
                width: 100%;
                height: 6px;
                margin: 0;
                padding: 0;
                text-align: center;
                list-style: none;

                li {
                    position: relative;
                    display: inline-block;
                    margin: 0 6px;
                    padding: 0;
                    text-align: center;
                    vertical-align: top;

                    button {
                        height: 6px;
                        width: 24px;
                        background: #fff;
                        -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                        box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                        opacity: 0.41;
                        border-radius: 3px;
                        font-size: 0;
                        border: 0;
                        color: transparent;
                        display: block;
                        transition: all .5s;
                        outline: none;
                        cursor: pointer;
                    }
                }

                li.slick-active {
                    button {
                        width: 44px;
                        opacity: 0.81;
                    }
                }
            }
        }

        .search {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 245px;
            z-index: 999;
            width: 890px;
            height: 68px;
            background: rgba(255, 255, 255, 1);
            border-radius: 8px;
            // display: flex;
            // align-items: center;

            .search-select {
                position: absolute;
                top: -45px;
                left: 0;
                color: rgba(255, 255, 255, 0.7);
                font-size: 20px;
                font-weight: bold;
                display: flex;
                flex-wrap: nowrap;
                z-index: 10;
                text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);

                div {
                    margin-right: 24px;
                    padding: 6px 0px;
                    user-select: none;
                }
            }

            .search-arrow {
                position: absolute;
                top: -19px;
                left: 0;
                font-size: 18px;
                font-weight: bold;
                width: 100%;
                display: flex;
                z-index: 10;

                div {
                    width: 0;
                    height: 0;
                    border: 9px solid transparent;
                    border-bottom: 10px solid #fff;
                    margin-left: 20px;
                    -webkit-transition: -webkit-transform 0.3s;
                    transition: -webkit-transform 0.3s;
                    transition: transform 0.3s;
                    transition: transform 0.3s, -webkit-transform 0.3s;
                    transform: translateX(0px);
                }
            }

            .search-input {
                flex: 1;
                position: relative;
                padding-left: 27px;
                display: flex;
                align-items: center;

                input {
                    width: 100%;
                    background: none;
                    height: 68px;
                    outline: none;
                    border: none;
                    font-size: 18px;
                }
            }

            .search-btn {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -moz-box-orient: horizontal;
                -moz-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: center;
                -moz-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 144px;
                height: 68px;
                background: rgba(203, 0, 24, .8);
                text-align: center;
                line-height: 68px;
                border-radius: 0 8px 8px 0;

                .search-icon {
                    height: 34px;
                    width: 34px;
                    background-size: 100% 100%;
                    background-image: url(https://cdn.code.znzmo.com/refactorHome/mainSearch.png);
                }
            }
        }
    }

    .con-title {
        text-align: center;
        height: auto;
        margin-bottom: 40px;
        margin-top: 61px;
        position: relative;

        .c-tit {
            font-size: 32px;
            color: #3f3f3f;
            margin-bottom: 16px;
            font-weight: bold;
        }

        .c-txt {
            font-size: 16px;
            color: #999999;
        }

        .more {
            position: absolute;
            right: 0;
            bottom: -4px;
            color: #999999;
            font-size: 14px;
            cursor: pointer;

            .icon {
                display: inline-block;
                background: url('~@/assets/img/icon_next.png') no-repeat;
                width: 20px;
                height: 20px;
                vertical-align: bottom;
            }
        }
    }

    .main {
        min-height: 100vh;
    }

    .compilation-wrap {
        width: 100%;
        display: flex;

        .con-l {
            width: 705px;
            height: 535px;
            max-height: 535px;
            margin: 0 25px 25px 0;
            border-radius: 5px;

            .model-cover {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                background-color: #fff;
                border-radius: 5px;
                transition: transform .2s linear;

                &:hover {
                    box-shadow: 0 8px 15px 0 rgb(0 0 0 / 15%);
                    transform: translateY(-5px);
                }

                .el-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;

                    /deep/ .el-image__inner {
                        object-fit: cover;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    transition: opacity .3s linear;
                    border-radius: 5px;
                    object-fit: cover;
                }

                .model-info {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    height: 60px;
                    background-image: linear-gradient(180deg, transparent, rgba(23, 23, 23, 0.6));
                    background-color: transparent;
                    border-radius: 0 0 5px 5px;
                    height: 75px !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 14px;
                    padding-left: 30px;

                    .title {
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }
        }

        .title_bt {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bt {
                font-size: 20px;
                color: #333333;
                font-weight: 600;
            }

            .more {
                color: #999999;
                font-size: 14px;
                cursor: pointer;

                .icon {
                    display: inline-block;
                    background: url('~@/assets/img/icon_next.png') no-repeat;
                    width: 20px;
                    height: 20px;
                    vertical-align: bottom;
                }
            }
        }

        .con-r {
            flex: 1;
        }

        .r-list {
            display: flex;
            flex-wrap: wrap;


            .model-item {
                width: 340px;
                height: 255px;
                overflow: hidden;
                position: relative;
                margin: 0 25px 25px 0;
                transition: transform .2s linear;
                border-radius: 5px;

                &:hover {
                    box-shadow: 0 8px 15px 0 rgb(0 0 0 / 15%);
                    transform: translateY(-5px);
                }

                .model-cover {
                    height: 255px;
                    width: 100%;
                    position: relative;

                    .el-image {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;

                        /deep/ .el-image__inner {
                            object-fit: cover;
                        }
                    }
                }
            }

            .model-item:nth-child(3n) {
                margin-right: 0;
            }

            .model-info {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 60px;
                background-image: linear-gradient(180deg, transparent, rgba(23, 23, 23, 0.6));
                background-color: transparent;
            }
        }

        .model-cover {
            display: block;
            width: 100%;
            height: 340px;
            background-color: #fff;
            border-radius: 5px;

            img {
                width: 100%;
                height: 100%;
                // opacity: .3;
                transition: opacity .3s linear;
                border-radius: 5px 5px 0 0;
                object-fit: cover;
            }
        }

        .model-info {
            width: 100%;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(180deg, transparent, rgba(23, 23, 23, 0.6));
            background-color: transparent;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px;
            background-color: #fff;

            .title {
                font-size: 18px;
                color: #fff;
            }

            p {
                flex: 2;
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }
        }
    }

    .bg-fa {
        background-color: #f5f5f5;
    }

    .edu-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .edu-item {
            position: relative;
            width: 432px;
            margin-right: 24px;
            margin-bottom: 24px;
            transition: all .3s;
            border-radius: 8px;

            &:hover {
                box-shadow: 0 8px 15px 0 rgb(0 0 0 / 15%);
                transform: translateY(-5px);
            }

            .list-img {
                cursor: pointer;
                width: 100%;
                height: 230px;
                border-radius: 8px 8px 0 0;
                overflow: hidden;
                position: relative;
                display: block;

                img {
                    width: 100%;
                }

                .tag {
                    width: 111px;
                    height: 38px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    transform: scale(1);
                }
            }

            .list-info {
                // height: 84px;
                background: #fff;
                border-bottom: 1px solid #fafafa;
                padding: 16px 24px;
                cursor: pointer;

                .list-info-title {
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                    line-height: 21px;
                    color: #333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    display: inline-block;
                    width: 100%;
                }

                .edu-info {
                    margin-top: 10px;
                    position: relative;
                    vertical-align: baseline;
                    display: flex;
                    align-items: center;

                    span {
                        height: 16px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999;
                        line-height: 16px;
                    }

                    .line {
                        color: #999;
                        margin-left: 10px;
                        margin-right: 10px;
                        vertical-align: top;
                    }

                    .priceVipFree {
                        width: 60px;
                        height: 22px;
                        background: linear-gradient(226deg, #ffd597 0%, #ffe7c5 51%, #ffe2b8 100%);
                        border-radius: 4px 4px 4px 4px;
                        opacity: 1;
                        font-size: 14px;

                        font-weight: 400;
                        color: #8b623c;
                        line-height: 22px;
                        display: inline-block;
                        text-align: center;
                        position: absolute;
                        right: 0px;
                        top: -3px;
                    }
                }
            }

            .userInfo {
                cursor: pointer;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                height: 72px;
                background: #fff;
                border-radius: 0 0 8px 8px;
                padding: 15px 24px;

                img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }

                .infodetail {
                    margin-left: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    // width: 290px;
                    .nickName {
                        height: 16px;
                        font-size: 14px;

                        font-weight: 600;
                        color: #333;
                        line-height: 16px;
                        vertical-align: top;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin-top: 1px;
                        display: inline-block;
                    }

                    .detail {
                        height: 14px;
                        font-size: 12px;

                        font-weight: 400;
                        color: #999;
                        line-height: 14px;
                        margin-top: 6px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .edu-item:nth-child(4n) {
            margin-right: 0;
        }
    }

    .d-model {
        .con-l {
            .model-item {
                width: 100%;
                height: 255px;
                margin-bottom: 25px;
                border-radius: 5px;
            }
        }
    }

    .tt-list {
        display: flex;

        .model-item {
            max-height: 386px;
            width: 340px;
            margin: 0 25px 25px 0;
            transition: transform .2s linear;
            border-radius: 5px;

            &:hover {
                box-shadow: 0 8px 15px 0 rgb(0 0 0 / 15%);
                transform: translateY(-5px);
            }

            .model-cover {
                display: block;
                width: 100%;
                height: 340px;
                background-color: #fff;
                border-radius: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    // opacity: .3;
                    transition: opacity .3s linear;
                    border-radius: 5px 5px 0 0;
                    object-fit: cover;
                }
            }

            .model-info {
                position: static;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 46px;
                padding: 0 14px;
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                font-size: 14px;

                .title {
                    color: #666;
                }

                .item-info {
                    color: #666;
                }
            }
        }

        .model-item:last-child {
            margin-right: 0;
        }
    }

    .sgt {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 25px;

        .sgt-item {
            border-radius: 5px;
            background-color: rgba(216, 216, 216, 0.3);
            overflow: hidden;
            position: relative;

            .model-cover {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #fff;
                border-radius: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    // opacity: .3;
                    transition: all 1s linear;
                    border-radius: 5px 5px 0 0;
                    object-fit: cover;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .model-info {
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 46px;
                padding: 0 14px;
                // background-color: #fff;
                border-radius: 0 0 5px 5px;
                font-size: 14px;
                background-image: linear-gradient(180deg, transparent, rgba(23, 23, 23, 0.6));
                background-color: transparent;

                .title {
                    color: #fff;
                }
            }
        }

        .sgt-item:nth-child(1) {
            width: 650px;
            height: 370px;
        }

        .sgt-item:nth-child(2),
        .sgt-item:nth-child(3) {
            width: 550px;
            height: 370px;
        }

        .sgt-item:nth-child(n+4) {
            margin-top: 25px;
            width: 431.25px;
            height: 270px;
        }
    }

    .teacher {
        .sgt-item:nth-child(1) {
            margin-top: 25px;
            width: 431.25px;
            height: 270px;
        }

        .sgt-item:nth-child(2),
        .sgt-item:nth-child(3) {
            margin-top: 25px;
            width: 431.25px;
            height: 270px;
        }

        .info {
            position: absolute;
            text-align: center;
            width: 100%;
            height: 100%;
            padding-top: 171px;
            background: rgba(30, 30, 30, 0.21);
            cursor: pointer;
            top: 0;

            p:first-child {
                font-size: 32px;
                color: #fff;
                font-weight: 600;
                line-height: 32px;
            }

            p:nth-child(2) {
                margin: 0 auto;
                margin-top: 18px;
                width: 216px;
                height: 24px;
                line-height: 24px;
                background-color: #fff;
                font-size: 18px;
                text-align: center;
                color: #333;
            }

            p:nth-child(3) {
                margin-top: 16px;
                color: #fff;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
            }
        }
    }

    @media only screen and (max-width: 1800px) {
        .banner {
            min-height: 600px;
        }

        .compilation-wrap {
            .con-l {
                width: 557px;
                height: 446px;
                max-height: 446px;
                margin-right: 18px;
            }

            .r-list .model-item {
                width: 296px;
                height: 214px;
                margin: 0 18px 18px 0;

                .model-cover {
                    height: 214px;
                }
            }
        }

        .edu-list .edu-item {
            margin-right: 18px;
            width: 361.5px;

            .list-img {
                height: 200px;
            }
        }

        .d-model {
            .con-l .model-item {
                margin-bottom: 18px;
                height: 214px;
            }
        }

        .tt-list .model-item {
            width: 280px;

            .model-cover {
                height: 300px;
            }
        }

        .sgt .sgt-item:nth-child(1) {
            width: 550px;
            height: 320px;
        }

        .sgt .sgt-item:nth-child(2),
        .sgt .sgt-item:nth-child(3) {
            width: 457px;
            height: 320px;
        }

        .sgt .sgt-item:nth-child(n+4) {
            margin-top: 18px;
            width: 361.5px;
            height: 250px;
        }

        .teacher .sgt-item:nth-child(n) {
            margin-top: 18px;
            width: 361.5px;
            height: 250px;
        }
    }

    @media only screen and (max-width: 1500px) {
        .banner {
            min-height: 550px;
        }

        .compilation-wrap {
            .r-list .model-item {
                width: 303px;
                height: 214px;
                margin: 0 18px 18px 0;

                .model-cover {
                    height: 214px;
                    width: 100%;
                }
            }

            .r-list .model-item:nth-child(5),
            .r-list .model-item:nth-child(6) {
                display: none;
            }

            .r-list .model-item:nth-child(3n) {
                margin-right: 18px;
            }

            .r-list .model-item:nth-child(2n) {
                margin-right: 0;
            }
        }

        .con-title {
            margin-top: 30px;
        }

        .edu-list .edu-item {
            margin-right: 18px;
            width: 286.5px;

            .list-img {
                height: 160px;
            }

            .list-info {
                border-radius: 0 0 8px 8px;
            }

            .userInfo {
                display: none;
            }
        }

        .tt-list .model-item:last-child {
            display: none;
        }

        .tt-list .model-item:nth-child(4) {
            margin-right: 0;
        }

        .tt-list .model-item {
            width: 285px;

            .model-cover {
                width: 285px;
                height: 300px;
            }
        }

        .sgt .sgt-item:nth-child(1) {
            width: 450px;
            height: 280px;
        }

        .sgt .sgt-item:nth-child(2),
        .sgt .sgt-item:nth-child(3) {
            width: 357px;
            height: 280px;
        }

        .sgt .sgt-item:nth-child(n+4) {
            margin-top: 18px;
            width: 286.5px;
            height: 220px;
        }

        .teacher .sgt-item:nth-child(n) {
            margin-top: 18px;
            width: 286.5px;
            height: 220px;

        }

        .teacher-test .sgt-item:nth-child(n) {
            margin-top: 18px;
            width: auto;
            height: inherit;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 10px;
                background-color: #000;

            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 10px;
                background-color: #000;
            }

            &:hover {
                background-color: #999;
                font-size: 15px;
                overflow: hidden;
                transform: scale(1.1);
                cursor: pointer;
            }
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .banner {
    //         .swiper {
    //             height: 300px;

    //             img {
    //                 object-fit: cover;
    //             }
    //         }

    //         .search {
    //             display: none;
    //         }
    //     }

    //     .compilation-wrap {
    //         flex-wrap: wrap;

    //         .con-l {
    //             width: 100%;
    //             margin-right: 0;
    //             max-height: none;
    //             height: auto;

    //             .model-cover {
    //                 height: 260px;
    //             }
    //         }

    //         .r-list {
    //             .model-item {
    //                 width: calc(50% - 5px);
    //                 margin-right: 10px;
    //                 height: 140px;

    //                 .model-cover {
    //                     height: 140px;
    //                 }
    //             }

    //             .model-item:nth-child(3n) {
    //                 margin-right: 10px;
    //             }

    //             .model-item:nth-child(2n) {
    //                 margin-right: 0;
    //             }
    //         }
    //     }

    //     .con-title .more {
    //         bottom: -30px;
    //         right: 50%;
    //         transform: translateX(50%);
    //     }

    //     .edu-list {
    //         flex-wrap: wrap;

    //         .edu-item {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;
    //             margin-bottom: 12px;

    //             .list-img {
    //                 height: auto;
    //                 border-radius: 4px 4px 0 0;

    //                 .tag {
    //                     width: 60px;
    //                     height: 20px;
    //                 }
    //             }

    //             .list-info {
    //                 height: auto;
    //                 padding: 10px;
    //                 border-radius: 0 0 4px 4px;

    //                 .edu-info {
    //                     display: none;
    //                 }

    //                 .list-info-title {
    //                     font-size: 13px;
    //                 }
    //             }
    //         }

    //         .edu-item:nth-child(2n) {
    //             margin-right: 0;
    //         }
    //     }

    //     .d-model .con-l {
    //         .model-item {
    //             height: 155px;

    //             .model-cover {
    //                 height: 155px;
    //             }
    //         }
    //     }

    //     .tt-list {
    //         flex-wrap: wrap;

    //         .model-item {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;
    //             height: 240px;
    //             margin-bottom: 10px;

    //             .model-cover {
    //                 height: 200px;
    //                 width: 100%;
    //             }

    //             .model-info {
    //                 height: auto;
    //                 padding: 10px;

    //                 .item-info {
    //                     display: none;
    //                 }
    //             }
    //         }

    //         .model-item:nth-child(3n) {
    //             margin-right: 10px;
    //         }

    //         .model-item:nth-child(2n) {
    //             margin-right: 0;
    //         }
    //     }

    //     .sgt .sgt-item:nth-child(1) {
    //         width: 100%;
    //         height: 260px;
    //         margin-bottom: 10px;
    //     }

    //     .sgt .sgt-item:nth-child(2),
    //     .sgt .sgt-item:nth-child(3) {
    //         width: 100%;
    //         height: 260px;
    //         margin-bottom: 10px;
    //     }

    //     .sgt .sgt-item:nth-child(n+4) {
    //         margin-top: 10px;
    //         width: calc(50% - 5px);
    //         // width: 286.5px;
    //         height: 140px;
    //     }

    //     .teacher .sgt-item:nth-child(n) {
    //         margin-top: 0px;
    //         width: 100%;
    //         height: 220px;

    //     }
    // }
</style>